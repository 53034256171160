<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="primary lighten-5"
          main-icon-text-color="primary--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Erfahrungsnoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-primary"
          :bottom-button-link="`/${$i18n.locale}/app/teacher/experience-marks`"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="success lighten-5"
          main-icon-text-color="success--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Zwischenbericht"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-success"
          :bottom-button-link="`/${$i18n.locale}/app/teacher/quarter-marks`"
        />
      </v-col>
      <!--
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="info lighten-5"
          main-icon-text-color="info--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Semesternoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-info"
          :bottom-button-link="`/${$i18n.locale}/app/apps/semester-marks-pma`"
        />
      </v-col>
      -->
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="warning lighten-5"
          main-icon-text-color="warning--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Abschlussnoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-warning"
          :bottom-button-link="`/${$i18n.locale}/app/teacher/final-marks`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <courses-list />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <timetable />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'
  import analyticTwoCard from '@/components/card/AnalyticCardVersionTwo'
  import Courses from '@/views/app/apps/Courses'
  import Timetable from '@/views/app/apps/TimetablePMA'
  import {
    analyticOne,
    analyticTwo,
    analyticThree,
    analyticFour,
    analyticFive,
  } from '@/data/analytic2'
  export default {
    name: 'Teacher',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Teacher',
    },
    components: {
      'analytic-one-card': analyticOneCard,
      'analytic-two-card': analyticTwoCard,
      'courses-list': Courses,
      'timetable': Timetable
    },
    data () {
      return {
        analyticOne,
        analyticTwo,
        analyticThree,
        analyticFour,
        analyticFive,
      }
    },
    methods: {
    },
  }
</script>
